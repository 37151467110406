import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Card} from 'react-bootstrap';
import OffersModal from '../pages/offers/OffersModal';
import {AttachedIcon, Delete, InvalidFileError, RejectAll} from '../svgs/components';
import {
	ERROR_MESSAGE,
	PAGINATION_OFFSET, SHEET_ERROR_STATUS,
	SHEET_SAVED_STATUS,
	SHEET_VALIDATED_STATUS,
	SUPPORTED_FILE_TYPES,
	TOAST_ERROR_STATUS,
	TOAST_SUCCESS_STATUS
} from '../utils/constants';
import {formatBytes, raiseToast, turncateAttachmentFileName} from "../utils/utils";
import {useAddMutation, useGetRequest} from "../hooks/useRequests";
import SpinLoader from './SpinLoader';
import {createAuthenticatedPostRequest} from "../utils/apiHelper";
import BaseModal from './BaseModal';
import {ErrorIcon} from '../svgs/components'
import {
	SAVE_OFFERS_URL,
	UPLOAD_OFFERS_URL,
	VALIDATE_OFFERS_URL,
	AWAITING_OFFERS_URL,
	CONFIGURATIONS_URL, OFFERS_POLLING_URL,
	REVOKE_VALIDATE_ATTACHMENT_URL
} from "../utils/urls";
import {ROUTES} from "../routes/Constants";
import {
	OFFERS_SAVE_SUCCESS_MESSAGE,
	OFFERS_VALIDATION_SUCCESS_MESSAGE,
	FILE_UPLOAD_SUCCESS_MESSAGE,
	FILE_UPLOAD_ERROR_MESSAGE,
	OFFERS_SAVE_FAILURE_MESSAGE,
	OFFERS_VALIDATION_FAILURE_MESSAGE
} from "../utils/messages";
import {UploadFile} from './UploadFile';
import UserService from "../services/userService";

const UploadOffersCard = ({ cardTitle, cardSubTitle }) => {
	const navigate = useNavigate();

	const [loadPercentage, setLoadPercentage] = useState(0);
	const [attachment, setAttachment] = useState(null);
	const [isInvalidAttachment, setIsInvalidAttachment] = useState(false);
	const [invalidAttachmentFile, setInvalidAttachmentFile] = useState(null);
	const [showOffersModal, setShowOffersModal] = useState(false);
	const [uploadedFileId, setUploadedFileId] = useState(null);
	const [offerDetails, setOfferDetails] = useState({});
	const [currency, setCurrency] = useState('');
	const [validatingOffers, setValidatingOffers] = useState(false);
	const [savingOffers, setSavingOffers] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [showExitPrompt, setShowExitPrompt] = useState(false);

	const { refetch: updatePendingOffers } = useGetRequest(
		'awaiting-offers',
		[],
		`${AWAITING_OFFERS_URL}?page=1&page_size=${PAGINATION_OFFSET.PAGINATION_OFFSET_10}`,
		null,
		{
			refetchOnWindowFocus: false,
			retry: false,
			enabled: false
		}
	);

	const { data: pollOfferData } = useGetRequest(
		'poll-offers',
		[],
		OFFERS_POLLING_URL(uploadedFileId),
		null,
		{
			refetchInterval: 3000,
			refetchOnWindowFocus: false,
			enabled: validatingOffers || savingOffers
		}
	)

	useEffect(() => {
		if (pollOfferData?.data?.status === SHEET_ERROR_STATUS) {
			raiseToast(TOAST_ERROR_STATUS, ERROR_MESSAGE)
			setSavingOffers(false);
			setValidatingOffers(false);
		}
		if (pollOfferData?.data?.status === SHEET_VALIDATED_STATUS && !pollOfferData?.data?.processed_file) {
			setValidatingOffers(false);
			raiseToast(TOAST_SUCCESS_STATUS, OFFERS_VALIDATION_SUCCESS_MESSAGE);
			setShowOffersModal(!!uploadedFileId);
		}
		if (pollOfferData?.data?.status === SHEET_SAVED_STATUS) {
			setSavingOffers(false);
			setShowOffersModal(false);
			raiseToast(TOAST_SUCCESS_STATUS, OFFERS_SAVE_SUCCESS_MESSAGE(pollOfferData?.data?.stats?.quantity));
			setAttachment(null);
			setOfferDetails({});
			updatePendingOffers();
			pollOfferData.data = null;
			navigate(`../${ROUTES.AWAITING_APPROVAL}`);
		}
		if (pollOfferData?.data?.processed_file) {
			setShowModal(true);
			setSavingOffers(false);
			setValidatingOffers(false);
		}
	}, [pollOfferData])

	const handleFileUploadSuccess = (data) => {
		raiseToast(TOAST_SUCCESS_STATUS, FILE_UPLOAD_SUCCESS_MESSAGE);
		setOfferDetails(data?.data);
		setUploadedFileId(data?.data?.id);
		setShowOffersModal(false);
	};

	const handleFileUploadFailure = (error) => {
		raiseToast(TOAST_ERROR_STATUS, error?.response?.data?.file?.[0]);
		setAttachment(null);
	};

	const getFormDataFile = () => {
		const formData = new FormData();
		formData.append("file", attachment)
		return formData
	};

	const uploadFile = async () => {
		setUploadedFileId(null);
		let config = {};
		const loaderFunction = (value) => setLoadPercentage(value?.toFixed(1))
		config.onUploadProgress = loaderFunction();

		try {
			const fileData = await createAuthenticatedPostRequest(
				UPLOAD_OFFERS_URL,
				getFormDataFile(),
				{
					...config,
					onUploadProgress:(progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setLoadPercentage(percentCompleted)
				},
			},
				
			);
			handleFileUploadSuccess(fileData);
		} catch (error) {
			handleFileUploadFailure(error)
		}
	};

	const { mutate: handleFileValidation, isLoading: loadingFileValidation, data: validateAttachmentDetail } = useAddMutation(
		'validate-attachment',
		[],
		VALIDATE_OFFERS_URL(offerDetails?.id),
		{},
		null,
		(success) => {setValidatingOffers(true)},
		(error) => {
			setShowOffersModal(false);
			setAttachment(null);
			setOfferDetails({});
			if (error?.response?.status !== 500) {
				raiseToast(TOAST_ERROR_STATUS, OFFERS_VALIDATION_FAILURE_MESSAGE(attachment.name));
				navigate(ROUTES.VALIDATION_ERRORS, { state: { data: error?.response?.data }});
			}
			raiseToast(TOAST_ERROR_STATUS, FILE_UPLOAD_ERROR_MESSAGE);
		}
	);

	const { mutate: handleFileSave } = useAddMutation(
		'save-attachment',
		[],
		SAVE_OFFERS_URL(offerDetails?.id),
		{},
		null,
		(success) => {
			setSavingOffers(true);
		},
		(error) => {
			navigate(ROUTES.VALIDATION_ERRORS, { state: { data: error?.response?.data }});
			setAttachment(null);
			setOfferDetails({});
			raiseToast(TOAST_ERROR_STATUS, OFFERS_SAVE_FAILURE_MESSAGE(attachment.name));
		},
	)

	const { data: configuration } = useGetRequest(
		'configuration-offers',
		[],
		CONFIGURATIONS_URL,
		null,
	);

	useEffect(() => {
		if (attachment) {
			uploadFile();
		}
	}, [attachment])

	const handleChange = attachment => {
		if (attachment.length > 0) {
			if (attachment[0].type === SUPPORTED_FILE_TYPES) {
				setAttachment(attachment[0]);
				setIsInvalidAttachment(false);
			} else {
				setInvalidAttachmentFile(attachment[0])
				setIsInvalidAttachment(true);
				setAttachment(null);
			}
		}
	};

	const handleCancelBtn = () => {
		setAttachment(null);
		setOfferDetails({});
		setUploadedFileId(null);
		setValidatingOffers(false);
	};

	const handleValidateOffer = () => {
		handleFileValidation();
		setShowExitPrompt(true);
		setValidatingOffers(true);
	};

	const handleUploadConfirmation = () => {
		handleFileSave();
	};

	useEffect(() => {
		setCurrency(configuration?.data?.results[0]?.currency);
	}, [configuration])

	window.onunload = function() {
		if (validateAttachmentDetail?.data?.task_id) {
			fetch(REVOKE_VALIDATE_ATTACHMENT_URL, {
				method: 'POST',
				body: JSON.stringify({
					"task_id": validateAttachmentDetail?.data?.task_id
				}),
				headers: {
					'Authorization': `Bearer ${UserService.getToken()}`,
					'Content-Type': 'application/json',
				},
				keepalive: true,
			});
		}
	}

	window.onload = function() {
		initBeforeUnLoad(showExitPrompt);
	};

	const initBeforeUnLoad = showExitPrompt => {
		window.onbeforeunload = event => {
			if (showExitPrompt) {
				const e = event || window.event;
				e.preventDefault();
				return '';
			}
		};
	};

	useEffect(() => {
		initBeforeUnLoad(showExitPrompt);
	}, [showExitPrompt])

	const handleErrorFileDownload = () => {
		setValidatingOffers(false);
		setSavingOffers(false);
		setShowOffersModal(false);
		setAttachment(null);
		setOfferDetails({});
		window.open(pollOfferData?.data?.processed_file);
		pollOfferData.data = null;
		setShowModal(false);
	}

	const handleErrorCancel = () => {
		setShowOffersModal(false);
		setValidatingOffers(false);
		setSavingOffers(false);
		setShowOffersModal(false);
		setAttachment(null);
		setOfferDetails({});
		pollOfferData.data = null;
		setShowModal(false);
	}

	return (
		<>
			<Card className='upload-offers-card'>
				<Card.Body>
					<Card.Title>{cardTitle}<a href={"/app/offers-template.xlsx"} className='btn btn-light'>Download template</a></Card.Title>
					<Card.Subtitle className='mb-2'>{cardSubTitle}</Card.Subtitle>
					<UploadFile handleChange={handleChange} loadingFileValidation={validatingOffers} />
				</Card.Body>
				{attachment && (
					<>
						<div className='file-upload-details'>
							<div className='attached-file-icon'>
								<AttachedIcon />
							</div>
							<div className='attached-file-overview'>
								<div className='attached-file-name'>
									<h4>{turncateAttachmentFileName(attachment?.name)}</h4>
									{!uploadedFileId && <div><SpinLoader variant="dark"/></div>}
								</div>
									{uploadedFileId ?
										<>
											<div className='attached-file-stats'>
												<span>Quantity: {offerDetails?.stats?.quantity}</span>
												<span>No of Retailers: {offerDetails?.stats?.no_of_retailers}</span>
												<span className={`delete-icon ${validatingOffers || savingOffers ? "disabled" : ""}`}>
													<Delete onClick={handleCancelBtn} />
												</span>
											</div>
											<span>Rewards amount: {offerDetails?.stats?.rewards_amount} {currency}</span>
										</> :
										<div className='attached-file-stats'>
											<span>{formatBytes(attachment?.size)} - {loadPercentage}% Uploaded</span>
										</div>
									}
							</div>
						</div>
						{
							uploadedFileId &&
							<div className='btn-wrapper'>
								<Button variant='light' onClick={handleCancelBtn} disabled={loadingFileValidation || validatingOffers}>Cancel</Button>
								<Button variant='primary' onClick={handleValidateOffer} disabled={loadingFileValidation || validatingOffers}>
									{
										(loadingFileValidation || validatingOffers) ?
											<span>
												{`${pollOfferData?.data?.validation_progress || 0}% validated`}
												<SpinLoader variant="light"/>
											</span> :
											<span>Validate Offers</span>
									}
								</Button>
							</div>
						}
					</>
				)}
				{isInvalidAttachment && (
					<div className='file-upload-details invalid-file'>
						<div className='attached-file-icon'>
							<InvalidFileError />
						</div>
						<div className='attached-file-overview'>
							<div className='attached-file-name'>
								<h4>{invalidAttachmentFile?.name}</h4>
								<div className='attached-file-stats'>
									<span>Upload Failed: File is not supported</span>
								</div>
							</div>
							<span className='delete-icon'>
								<RejectAll onClick={() => setIsInvalidAttachment(false)} />
							</span>
						</div>
					</div>
				)}
			</Card>
			{
				showOffersModal &&
				<OffersModal
					showOffersModal={showOffersModal}
					handleClose={
						() => {
							setShowOffersModal(false);
							setSavingOffers(false);
						}
					}
					handleConfirmation={handleUploadConfirmation}
					message={`You are about to save offers with a total reward amount of ${offerDetails?.stats?.rewards_amount} ${currency}`}
					heading={'Save Offer'}
					currency={currency}
					loading={loadingFileValidation || savingOffers}
					savingMessage={`${pollOfferData?.data?.saving_progress || 0}% saved`}
				/>
			}
			{
				showModal &&
				<BaseModal
					loading={false}
					showModal={true}
					heading={'Download Error'}
					message={`Provided file encountered errors. Click download button for detail error log`}
					handleSave={handleErrorFileDownload}
					handleClose={handleErrorCancel}
					modalIcon={<ErrorIcon />}
				/>
			}
		</>
	);
};

export default UploadOffersCard;
