import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import * as Yup from 'yup';
import { useGetRequest, useAddMutation } from "../../hooks/useRequests";
import {
  CAN_CHANGE_BUDGET,
  TOAST_ERROR_STATUS,
  TOAST_SUCCESS_STATUS
} from "../../utils/constants";
import { useFormik } from 'formik';
import {raiseToast, validatePermission} from "../../utils/utils";
import {CONFIGURATIONS_URL, OFFERS_BUDGET_URL} from "../../utils/urls";
import { CONFIG_BUDGET_ERROR_MESSAGE, CONFIG_BUDGET_SUCCESS_MESSAGE } from '../../utils/messages';
import SpinLoader from '../../common/SpinLoader';
import { isEqual } from 'lodash';
import Loader from '../../common/Loader';

const OffersBudget = ({permissions}) => {
  const [currency, setCurrency] = useState('');
  const [isOffersBudgetUpdate, setIsOffersBudgetUpdate] = useState(false);
  const canChangeBudget = validatePermission(CAN_CHANGE_BUDGET, permissions);

  const { data, isFetching } = useGetRequest(
      'offers-budget',
      [],
      OFFERS_BUDGET_URL,
      null,
  );

  const { data: configuration } = useGetRequest(
    'configuration-offers',
    [],
    CONFIGURATIONS_URL,
    null,
  );

  const validationSchema = Yup.object().shape({
    offers_reward: Yup.number().required("Offers budget is required")
      .positive("Offers budget should be a positive integer")
      .moreThan(0,"Offers budget should be greater than 0")
      .integer("Offers budget should be an integer"),
    num_days: Yup.number().required("Budget period is required")
      .positive("Budget period should be a positive integer")
      .moreThan(0,"Budget period should be greater than 0")
      .integer("Budget period should be an integer"),
    offer_reward: Yup.number().required("Max reward is required")
      .positive("Max reward should be a positive integer")
      .moreThan(0,"Max reward should be greater than 0")
      .integer("Max reward should be an integer"),
  });

  const formik = useFormik({
    initialValues: {
      offers_reward: '',
      num_days: '',
      offer_reward: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      addBudget();
    },
  });

  useEffect(() => {
    formik.setFieldValue('offers_reward', data?.data?.results[0]?.offers_reward)
    formik.setFieldValue('num_days', data?.data?.results[0]?.num_days)
    formik.setFieldValue('offer_reward', data?.data?.results[0]?.offer_reward)
  }, [data?.data?.results])

  const { mutate: addBudget, isLoading: loading } = useAddMutation(
      'offers-budget',
      [],
      OFFERS_BUDGET_URL,
      formik.values,
      null,
      () => { 
        raiseToast(TOAST_SUCCESS_STATUS, CONFIG_BUDGET_SUCCESS_MESSAGE)
        setIsOffersBudgetUpdate(true);
      },
      (error) => { raiseToast(TOAST_ERROR_STATUS, CONFIG_BUDGET_ERROR_MESSAGE) }
  );

  const handleCancelOffersBudget = () => {
    formik.setFieldValue('offers_reward', data?.data?.results[0]?.offers_reward)
    formik.setFieldValue('num_days', data?.data?.results[0]?.num_days)
    formik.setFieldValue('offer_reward', data?.data?.results[0]?.offer_reward)
  }

  useEffect(() => {
    setCurrency(configuration?.data?.results[0]?.currency);
  }, [configuration])

  useEffect(() => {
    if (isEqual(formik.values, data?.data?.results[0])) {
      setIsOffersBudgetUpdate(true)
    } else {
      setIsOffersBudgetUpdate(false)
    }

  }, [formik.values])

  return (
    <>
      {isFetching ? <Loader /> : (
        <div className='offers-budget'>
        <form className='form' onSubmit={formik.handleSubmit} onReset={handleCancelOffersBudget}>
          <div className="form-group">
            <Form.Label>Offers budget</Form.Label>
            <Form.Control
              id="offers_reward"
              name="offers_reward"
              type="number"
              placeholder={`12,000 ${currency}`}
              onChange = {formik.handleChange}
              value={formik.values.offers_reward}
              disabled={!canChangeBudget}
            />
            {(formik.touched.offers_reward && formik.errors.offers_reward)? (<div className='errors'>{formik.errors.offers_reward}</div>) : null}
          </div>
          <div className="form-group">
            <Form.Label>Budget period</Form.Label>
            <Form.Control 
              id="num_days"
              name="num_days"
              type="number"
              placeholder="14 Days"
              onChange={formik.handleChange}
              value={formik.values.num_days}
              disabled={!canChangeBudget}
            />
            {(formik.touched.num_days && formik.errors.num_days)? (<div className='errors'>{formik.errors.num_days}</div>) : null}
          </div>
          <div className="form-group">
            <Form.Label>Max reward per offers</Form.Label>
            <Form.Control 
              id="offer_reward"
              name="offer_reward"
              type="number"
              placeholder={`100 ${currency}`}
              onChange={formik.handleChange}
              value={formik.values.offer_reward}
              disabled={!canChangeBudget}
              />
              {( formik.touched.offer_reward && formik.errors.offer_reward)? (<div className='errors'>{formik.errors.offer_reward}</div>) : null}
          </div>
          {
            canChangeBudget &&
              <div className='btn-wrapper'>
                <Button variant="light" type='reset' disabled={loading || isOffersBudgetUpdate}>
                  Cancel
                </Button>
                <Button variant='primary' type='submit' disabled={loading || isOffersBudgetUpdate}>
                  {loading ? <span>Saving...<SpinLoader variant="light"/></span> : <span>Save changes</span> }
                </Button>
              </div>
          }
        </form>
      </div>
      )}
    </>
  )
}

export default OffersBudget;
