import React, {useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import {
  CAN_CHANGE_CONFIGURATION,
  DEFAULT_CONFIG_COUNTRY,
  TOAST_ERROR_STATUS,
  TOAST_SUCCESS_STATUS
} from "../../utils/constants";
import {useAddMutation, useGetRequest} from "../../hooks/useRequests";
import {CONFIGURATIONS_CHOICES_URL, CONFIGURATIONS_URL} from "../../utils/urls";
import {raiseToast, validatePermission} from "../../utils/utils";
import {
  CONFIG_TIMEZONE_AND_CURRENCY_ERROR_MESSAGE,
  CONFIG_TIMEZONE_AND_CURRENCY_SUCCESS_MESSAGE
} from '../../utils/messages';
import SpinLoader from '../../common/SpinLoader';
import Loader from '../../common/Loader';

const TimezoneAndCurrency = ({permissions}) => {
  const canChangeConfigurations = validatePermission(CAN_CHANGE_CONFIGURATION, permissions);
  const [country, setCountry] = useState("");
  const [timezone, setTimezone] = useState("");
  const [currency, setCurrency] = useState(DEFAULT_CONFIG_COUNTRY);
  const [timezoneChoices, setTimezoneChoices] = useState([]);
  const [defaultTimezone, setDefaultTimezone] = useState("");
  const [isTimeAndCurrencyUpdate, setIsTimeAndCurrencyUpdate] = useState(false);

  const { data: configurationChoices } = useGetRequest(
    'configuration-choices',
    [],
    CONFIGURATIONS_CHOICES_URL,
    null,
  );

  const { data: configuration, isFetching } = useGetRequest(
    'configuration',
    [],
    CONFIGURATIONS_URL,
    null,
  );

  const { mutate: updateConfiguration, isLoading: loading } = useAddMutation(
      'configuration',
      [],
      CONFIGURATIONS_URL,
      {
        'country': country,
        'timezone': timezone,
        'currency': currency,
      },
      null,
      () => { 
        raiseToast(TOAST_SUCCESS_STATUS, CONFIG_TIMEZONE_AND_CURRENCY_SUCCESS_MESSAGE)
        setIsTimeAndCurrencyUpdate(true);
      },
      (error) => { raiseToast(TOAST_ERROR_STATUS, CONFIG_TIMEZONE_AND_CURRENCY_ERROR_MESSAGE) }
  );

  useEffect(() => {
    const _country = configuration?.data?.results[0]?.country;
    const _currency = configuration?.data?.results[0]?.currency;
    const _timezone = configuration?.data?.results[0]?.timezone_display;

    if (_country) { setCountry(_country) }
    if (_currency) { setCurrency(_currency) }
    if (_timezone) {
      setDefaultTimezone(_timezone);
      setTimezone(timezoneChoices.filter(obj => obj.label === _timezone)[0]?.value);
    }
    
  }, [configuration, timezoneChoices])

  const onSubmit = () => {
    updateConfiguration();
  };

  const onCancel = () => {
    const _country = configuration?.data?.results[0]?.country;
    const _currency = configuration?.data?.results[0]?.currency;
    const _timezone = configuration?.data?.results[0]?.timezone_display;

    setCountry(_country);
    setCurrency(_currency);
    setTimezone(timezoneChoices.filter(obj => obj.label === _timezone)[0]?.value);
  };

  const handleTimeZoneChange = e => {
    setTimezone(e.target.value)
  }

  useEffect(() => {
    if (configurationChoices) {
      let arrReformat = configurationChoices?.data?.timezones.map(item => ({
        value: item[0],
        label: item[1]
      }))
      setTimezoneChoices(arrReformat)
    }
  }, [configurationChoices]);

  useEffect(() => {
    if (
      country === configuration?.data?.results[0]?.country &&
      currency === configuration?.data?.results[0]?.currency &&
      timezone === timezoneChoices.filter(obj => obj.label === defaultTimezone)[0]?.value
    ) {
      setIsTimeAndCurrencyUpdate(true)
    } else {
      setIsTimeAndCurrencyUpdate(false)
    }
  }, [configurationChoices, country, currency, timezone])

  return (
    <>
      {isFetching ? <Loader /> : (
        <div className='timezone-and-currency'>
          <Form className='form'>
            <div className="form-group">
              <Form.Label>Country/ Region</Form.Label>
              <div className="">
                <ReactFlagsSelect
                  className='country'
                  selected={country}
                  disabled={!canChangeConfigurations}
                  countries={configurationChoices?.data?.countries?.map(item => item[0])}
                  onSelect={(code) => {
                    setCountry(code)
                  }}
                />
              </div>
            </div>
            <div className='form-group timezone-field'>
            <Form.Label>Timezone</Form.Label>
            <div className="timezone">
              <select className='form-control' value={timezone} onChange={handleTimeZoneChange}>
                {timezoneChoices?.map(item => (
                    <option key={item.label} value={item.value} selected={true}>{item.value} {item.label}</option>
                  ))
                }
              </select>
            </div>
            </div>
            <div className="form-group">
              <Form.Label>Currency</Form.Label>
              <div className="currency">
                <select disabled={!canChangeConfigurations} className='form-select' onChange={e => setCurrency(e?.target?.value)}>
                  {
                    configurationChoices?.data?.currencies?.map((item) => (
                      currency === item[0] ?
                        <option selected={true} defaultValue value={item[0]}>{item[1]}</option> :
                        <option defaultValue value={item[0]}>{item[1]}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            {canChangeConfigurations &&
              <div className='region'>
                <div>
                </div>
                <div className='btn-wrapper'>
                  <Button variant="light" onClick={onCancel} disabled={loading || isTimeAndCurrencyUpdate}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={onSubmit} disabled={loading || isTimeAndCurrencyUpdate}>
                    {loading ? <span>Saving...<SpinLoader variant="light"/></span> : <span>Save changes</span> }
                  </Button>
                </div>
              </div>
            }
          </Form>
        </div>
      )}
    </>
  )
}

export default TimezoneAndCurrency;
