export const ROUTES = {
  HOME: '/',
  OFFERS: '/offers',
  ALL_OFFERS: 'all-offers',
  AWAITING_APPROVAL: 'awaiting-approval',
  UPLOAD_OFFERS: 'upload-offers',
  CONFIGURATIONS: '/configurations',
  CONFIGURATIONS_OFFERS_BUDGET: 'offers-budget',
  CONFIGURATIONS_TIME_AND_CURRENCY: 'time-and-currency',
  ACCESS_DENIED: '/access',
  LOGIN: '/login',
  VALIDATION_ERRORS: '/validation-errors'
};
