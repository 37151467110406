import React, { useEffect, useState } from 'react';
import Offers from '../pages/offers/Offers';
import SideBarMenu from './SideBarMenu';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {ROUTES} from '../routes/Constants';
import Configurations from '../pages/configuration/Configurations';
import Access from '../pages/access/Access';
import Login from '../pages/login/Login';
import ValidationErrors from "../pages/offers/ValidationErrors";
import UserService from "../services/userService";
import {useGetRequest} from "../hooks/useRequests";
import Protected from "../pages/Protected";
import {NON_AUTH} from "../utils/constants";
import {USER_ME_URL} from "../utils/urls";
import {Toaster, toast} from "react-hot-toast";
import AllOffers from '../pages/offers/AllOffers';
import AwaitingApproval from '../pages/offers/AwaitingApproval';
import UploadOffersCard from './UploadOffersCard';
import OffersBudget from '../pages/configuration/OffersBudget';
import TimezoneAndCurrency from '../pages/configuration/TimezoneAndCurrency';

const AuthenticatedWrapper = () => {
    const { pathname } = useLocation();

    const token = UserService.getToken();
    const [permissions, setPermissions] = useState();

    const {data, refetch: getPermissions} = useGetRequest(
        'user-details',
        [],
        USER_ME_URL,
        null,
        {
            refetchOnWindowFocus: false,
            retry: false,
            enabled: false
        }
    );

    useEffect(() => {
        if (!token) {
            UserService.doLogin();
        }
    }, [token]);

    useEffect(() => {
        if (!permissions && token) {
            getPermissions();
        }
    }, [permissions]);

    useEffect(() => {
        setPermissions(data?.data?.permissions)
    }, [data]);

    useEffect(() => {
        toast.remove();
    }, [pathname])

	const AuthScreen = () => {
		return (
          <div className='authenticated-wrapper'>
              {
                  token &&
                  <>
                    {!NON_AUTH.includes(pathname) && (
                        <div className='sidebar-menu-wrapper'>
                            <SideBarMenu />
                        </div>
                    )}
                      <div className='authenticated-content-wrapper'>
                          <Routes>
                              <Route
                                  path={ROUTES.HOME}
                                  element={
                                    <Protected permissions={permissions}>
                                        <Navigate replace to={ROUTES.OFFERS} />
                                    </Protected>
                                  }
                              />
                              <Route
                                path={ROUTES.OFFERS}
                                element={
                                    <Protected permissions={permissions}>
                                        <Offers permissions={permissions} />
                                    </Protected>
                                }
                              >
                                <Route
                                    path={ROUTES.ALL_OFFERS}
                                    element={<AllOffers permissions={permissions} />}
                                />
                                <Route
                                    path={ROUTES.AWAITING_APPROVAL}
                                    element={<AwaitingApproval permissions={permissions} />}
                                />
                                <Route 
                                    path={ROUTES.UPLOAD_OFFERS}
                                    element={
                                        <div className='upload-offers-wrapper'>
                                            <UploadOffersCard
                                                cardTitle="Upload offers"
                                                cardSubTitle="Upload offers sheet to send them to retailers"
                                                permissions={permissions}
                                            />
                                        </div>
                                    }
                                />
                              </Route>
                              <Route
                                path={ROUTES.CONFIGURATIONS}   
                                element={
                                    <Protected permissions={permissions}>
                                      <Configurations permissions={permissions} />
                                    </Protected>
                                }
                              >
                                <Route
                                    path={ROUTES.CONFIGURATIONS_OFFERS_BUDGET}
                                    element={<OffersBudget permissions={permissions} />}
                                />
                                <Route
                                    path={ROUTES.CONFIGURATIONS_TIME_AND_CURRENCY}
                                    element={<TimezoneAndCurrency permissions={permissions} />}
                                />
                              </Route>
                              <Route
                                  path={ROUTES.VALIDATION_ERRORS}
                                  element={
                                      <Protected permissions={permissions}>
                                        <ValidationErrors />
                                      </Protected>
                                  }
                              />
                              <Route path={ROUTES.ACCESS_DENIED} element={<Access />} />
                              <Route path={ROUTES.LOGIN} element={<Login />} />
                          </Routes>
                          <Toaster containerClassName="toastContainer" position="bottom-center" />
                      </div>
                  </>
              }
          </div>
        );
	};

	return <AuthScreen />;
};

export default AuthenticatedWrapper;
