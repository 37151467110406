import React from 'react'
import Header from '../../common/Header'
import ConfigurationsTabs from './ConfigurationsTabs'

const Configurations = ({permissions}) => {
  return (
    <>
      <Header title='Configurations' subTitle='Manage the E-money applications'/>
      <ConfigurationsTabs permissions={permissions} />
    </>
  )
}

export default Configurations;
